<template>
  <div class="manage-property animatedBox">
    <div class="container fluid">
      <!-- ===================== Filter ===================== -->
      <filter-panel
        v-model="isFilterActive"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter.referenceCode"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Reference Code"
            name="referenceCode"
            type="text"
          ></fd-input>
          <fd-input
            v-model="filter['title[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Title"
            name="title"
            type="text"
          ></fd-input>
          <fd-input
            v-model="filter.category"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Category"
            name="category"
            type="text"
          ></fd-input>
          <fd-input
            v-model="filter.furnishingType"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Furnish Type"
            name="furnishingType"
            type="text"
          ></fd-input>
          <fd-input
            v-model="filter.furnishingType"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Furnish Type"
            name="furnishingType"
            type="text"
          ></fd-input>
          <fd-input
            v-model="filter.purpose"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Purpose"
            name="purpose"
            type="text"
          ></fd-input>
        </div>
      </filter-panel>

      <!-- =================== Table Listing =================== -->
      <div class="card">
        <!-- Table -->
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="propertyTableColumns"
          :rows="propertyTableData"
          :totalRows="propertyTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="(e) => toPropertyDetail(e.row.id)"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'" @click.stop>
              <dropdown-button buttonClass="circle flat">
                <template #buttonContent>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <template>
                  <div class="card text-left">
                    <!-- <fd-list-item
                      class="cursor-pointer unselectable"
                      @click="toEditProperty(props.row.id)"
                    >
                      <i class="fas fa-pen"></i>
                      <span class="ml-2">Edit Property</span>
                    </fd-list-item> -->
                    <fd-list-item
                      class="cursor-pointer unselectable"
                      @click="openChangePrice(props.row)"
                    >
                      <i class="fas fa-tags"></i>
                      <span class="ml-2">Change Price</span>
                    </fd-list-item>
                    <fd-list-item
                      class="cursor-pointer unselectable"
                      @click="openChangeStatus(props.row)"
                    >
                      <i class="fas fa-lightbulb"></i>
                      <span class="ml-2">Change Status</span>
                    </fd-list-item>
                    <!-- TODO: Wait for delete API -->
                    <!-- <fd-list-item @click="deleteProperty(props.row.id)">
                      <i class="fas fa-trash"></i>
                      <span class="ml-2">Delete Property</span>
                    </fd-list-item> -->
                  </div>
                </template>
              </dropdown-button>
            </span>
            <span v-else-if="props.column.field == 'status'">
              <span
                class="btn mr-1"
                :class="getStatusButtonClass(props.row.status)"
              >
                {{ props.row.status }}
              </span>
            </span>
            <span v-else-if="props.column.field == 'title'">
              <exclusive-badge v-if="props.row.isExclusive"></exclusive-badge>
              {{ props.row.title }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!-- ===================== Modal for Change Status ===================== -->
    <modal v-model="isChangeStatusShown">
      <change-status
        :property="propertyToChangeStatus"
        @close="isChangeStatusShown = false"
        @update="updateStatus"
      ></change-status>
    </modal>
    <!-- ===================== Modal for Change Price ====================== -->
    <modal v-model="isChangePriceShown">
      <change-price
        :property="propertyToChangePrice"
        @close="isChangePriceShown = false"
        @update="updatePrice"
      ></change-price>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import propertyStatusEnum from "@/enums/propertyStatus";

import { PropertyModel } from "@/models";

export default {
  name: "manage-property",
  mixins: [vueGoodTable],
  components: {
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem"),
    ChangeStatus: () => import("./changeStatus"),
    ChangePrice: () => import("./changePrice"),
    ExclusiveBadge: () =>
      import("@/components/GlobalComponents/Shared/Property/ExclusiveBadge")
  },
  data() {
    return {
      propertyTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Status",
          field: "status",
          tdClass: "text-center"
        },
        {
          label: "Reference Code",
          field: "referenceCode"
        },
        {
          label: "Title",
          field: "title"
        },
        {
          label: "Category",
          field: "propertyCategory"
        },
        {
          label: "Furnish",
          field: "furnishingType"
        },
        {
          label: "Purpose",
          field: "purpose"
        },
        {
          label: "Sale Price",
          field: "salePrice",
          formatFn: (value) => {
            return `RM ${value}`;
          }
        },
        {
          label: "Rent Price",
          field: "rentPricePerUnit",
          formatFn: (value) => {
            return `RM ${value}`;
          }
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      propertyTableData: [],
      propertyTablePagination: {},
      tableActionOptions: [
        {
          label: "Edit Property"
        },
        {
          label: "Delete Property"
        },
        {
          label: "Change Status"
        }
      ],
      isFilterActive: false,
      filter: {
        referenceCode: "",
        "title[partial]": "",
        category: "",
        furnishingType: "",
        purpose: ""
      },
      // Change status
      propertyStatusEnum,
      isChangeStatusShown: false,
      propertyToChangeStatus: {},
      // Change price
      isChangePriceShown: false,
      propertyToChangePrice: {}
    };
  },
  mounted() {
    this.getAllProperties({ limit: this.tableParams.perPage });
  },
  methods: {
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllProperties();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllProperties();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddProperty() {
      this.$router.push({ name: "ManagePropertyAdd" });
    },
    toEditProperty(id) {
      this.$router.push({ name: "ManagePropertyEdit", params: { id: id } });
    },
    toPropertyDetail(id) {
      this.$router.push({ name: "ManagePropertyDetail", params: { id: id } });
    },
    openChangeStatus(property) {
      this.propertyToChangeStatus = property;
      this.isChangeStatusShown = true;
    },
    openChangePrice(property) {
      this.propertyToChangePrice = property;
      this.isChangePriceShown = true;
    },
    getStatusButtonClass(status) {
      switch (status) {
        case this.propertyStatusEnum.ACTIVE:
          return "success";
        case this.propertyStatusEnum.INACTIVE:
          return "warn";
        case this.propertyStatusEnum.SOLD:
          return "secondary";
        case this.propertyStatusEnum.DEALT_BY_OTHER:
          return "";
        case this.propertyStatusEnum.DELETED:
          return "danger";
        case this.propertyStatusEnum.RENT_OUT:
          return "accent2";
        case this.propertyStatusEnum.CONFIRMED:
          return "accent";
        case this.propertyStatusEnum.CLOSED:
          return "";

        default:
          break;
      }
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      this.getAllProperties();
    },
    clearFilter() {
      if (this.$isFilterEmpty(this.filter)) {
        return;
      } else {
        this.tableParams.page = 1;
        this.filter = this._.mapValues(this.filter, () => null);
        this.getAllProperties();
      }
    },

    // ============================= API Related ===============================
    async getAllProperties() {
      this.$store.commit("setIsLoading", true);
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);
        let data = await this.$store.dispatch(
          "manageProperty/getAllProperties",
          {
            ...filteredParam,
            page: this.tableParams.page,
            limit: this.tableParams.perPage
          }
        );

        this.propertyTableData = this._.cloneDeep(data.data);
        this.propertyTablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    deleteProperty(id, refCode) {
      this.deleteModal.message = `Are you sure you want to delete property ${refCode}?`;
      this.$confirm({ message: this.deleteModal.message, type: "alert" }).then(
        (confirmed) => {
          if (confirmed) {
            this.confirmDeleteProperty(id);
          }
        }
      );
    },
    async confirmDeleteProperty(id) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.$store.dispatch("manageProperty/deleteManageProperty", {
          id: id
        });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async updateStatus({ id, status }) {
      this.$store.commit("setIsLoading", true);
      console.log(status);
      try {
        this.isChangeStatusShown = false;
        await this.$store.dispatch("manageProperty/updateStatus", {
          id: id,
          status: PropertyModel.updateStatusPayload({ status: status })
        });

        this.$store.commit("setIsLoading", false);
        await this.getAllProperties();
        alert("Status has been updated successfully.");
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async updatePrice({ id, priceData }) {
      this.$store.commit("setIsLoading", true);
      try {
        this.isChangePriceShown = false;
        await this.$store.dispatch("manageProperty/updatePrice", {
          id: id,
          payload: PropertyModel.updatePricePayload(priceData)
        });
        this.$store.commit("setIsLoading", false);
        await this.getAllProperties();
        alert("Price data has been updated successfully.");
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-property {
  .btn.flat {
    &.toggle {
      background: none;
      color: #9b9b9b;
      &:hover {
        background: #e6e6e6;
      }
      &.active {
        background: $color-main;
        color: white;
        box-shadow: 0px 4px 10px #00000025;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}
</style>
